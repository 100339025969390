import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerParametros() {
    var url = ConfigAPI.baseURL + "parameters";

    return instance.get(url);
  },  
  mostrarLeads(id) {
    var url = ConfigAPI.baseURL + "public/sales-flow-leads/" + id;
    return instance.get(url);
  },
  obtenerLeadsLostMotive() {
    var url = ConfigAPI.baseURL + "public/sales-flow-leads-lost-motives";
    return instance.get(url);
  },  
  guardarApproval(data, image1) { 
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="firm") {
        formData.append(key, data[key]);
      }

      if(key=="firm") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData   
    var url = ConfigAPI.baseURL + "public/sales-flow-leads/approval";
    return instance.post(url,params,{headers});
  },  
  guardarDeny(data) {    
    var params = data   
    var url = ConfigAPI.baseURL + "public/sales-flow-leads/deny";
    return instance.post(url,params);
  },   
}

export default servicesAPI;

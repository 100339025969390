<template>
  <div>
    <b-navbar toggleable="sm" type="dark" variant="light" class="sales-flow-public-approval-nav">        
      <b-navbar-brand class="navbar-brand mx-auto">
        <img class="navbar-brand-full" :src="logo" fluid height="50" :alt="proyectName" />          
      </b-navbar-brand>
    </b-navbar>

    <b-container class="mt-2" v-show="crud.form.status=='interested' || crud.form.status=='in process'">
      <b-alert show variant="info" class="sales-flow-leads-alert">
        <h4 class="alert-heading">Alta de cuenta en proceso</h4>
        <p>
          Se está preparando toda los datos para dar proceder al alta de cuenta
        </p>        
      </b-alert>    
    </b-container>

    <b-container class="mt-2" v-show="crud.form.status=='approval'">
      <b-row>
        <b-col sm="12">                      
          <b-card header="Datos"
                  header-bg-variant="dark"
                  header-text-variant="withe"
                  class="mt-1 mb-1">      

            <div slot="header" v-if="isCompany">
              <strong>Datos de la Empresa</strong>                
            </div> 
            <div slot="header" v-else>
              <strong>Datos Personales</strong>                
            </div>

            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark" justified>    
              <b-tab title="Persona" v-if="!isCompany">
                <b-card-text>
                  <b-row>
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Nombre</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.name}}
                            </td>
                          </tr> 
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Documento</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.documents_types_name}}
                            </td>
                          </tr>     
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Documento Fiscal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.fiscal_conditions_name}}
                            </td>
                          </tr>          
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Categoría</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.categories_name}}
                            </td>
                          </tr>                                                                       
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Apellido</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.last_name}}
                            </td>
                          </tr> 
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Nº Documento</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.documents_number}}
                            </td>
                          </tr>    
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Nº Documento Fiscal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.documents_fiscal_number}}
                            </td>
                          </tr>        
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Origen</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.source_name}}
                            </td>
                          </tr>                                                                          
                        </tbody>
                      </table>                        
                    </b-col>
                    <b-col md="12" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Actividad Principal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.main_activity}}
                            </td>
                          </tr> 
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Observaciones</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.observations}}
                            </td>
                          </tr>                             
                        </tbody>
                      </table>
                    </b-col>                                                    
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Complementario" v-if="!isCompany && parameters.dataComplementary">
                <b-card-text>
                  <b-row>
                    <b-col md="6" sm="12">
                      
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Genero</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.gender}}
                            </td>
                          </tr> 
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Lugar de Nacimiento</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.place_birth}}
                            </td>
                          </tr>                             
                        </tbody>
                      </table>
                    </b-col>   

                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Estado Civil</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.marital_status_name}}
                            </td>
                          </tr> 
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Fecha de Nacimiento</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              <div v-if="this.crud.form.birthday">
                                {{moment(this.crud.form.birthday).format('DD/MM/YYYY')}}
                              </div>
                            </td>
                          </tr>                             
                        </tbody>
                      </table>
                    </b-col>  

                    <b-col md="12" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Nacionalidad</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.nationality}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>                  
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Empresa" active v-if="isCompany">   
                <b-card-text>
                  <b-row>
                    <b-col md="6" sm="12">

                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Razón Social</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.name}}
                            </td>
                          </tr>      
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Documento Fiscal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.fiscal_conditions_name}}
                            </td>
                          </tr>          
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Categoría</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.categories_name}}
                            </td>
                          </tr>                                                                       
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="6" sm="12">

                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Condición Fiscal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.fiscal_conditions_name}}
                            </td>
                          </tr>  
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Nº Documento Fiscal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.documents_fiscal_number}}
                            </td>
                          </tr>        
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Origen</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.source_name}}
                            </td>
                          </tr>                                                                          
                        </tbody>
                      </table>
                    </b-col>                       
                    <b-col md="12" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Actividad Principal</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.main_activity}}
                            </td>
                          </tr> 
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Observaciones</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.observations}}
                            </td>
                          </tr>                             
                        </tbody>
                      </table>
                    </b-col>                                             
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Complementario" v-if="isCompany && parameters.dataComplementary">   
                <b-card-text>
                  <b-row>
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Inscripcíon Registro Público</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              <div v-if="this.crud.form.registration_public_registry">
                                {{moment(this.crud.form.registration_public_registry).format('DD/MM/YYYY')}}
                              </div>
                            </td>
                          </tr> 
                        </tbody>
                      </table>               
                    </b-col>                   
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Fecha Cierre Ejercicio</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              <div v-if="this.crud.form.year_end_date">
                                {{moment(this.crud.form.year_end_date).format('DD/MM/YYYY')}}
                              </div>
                            </td>
                          </tr>                             
                        </tbody>
                      </table>   
                    </b-col>

                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Distrito de Inscripción</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">                                
                              {{this.crud.form.registration_district}}                               
                            </td>
                          </tr>                             
                        </tbody>
                      </table>                                 
                    </b-col>  
  
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Fecha de Registro</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              <div v-if="this.crud.form.registration_date">
                                {{moment(this.crud.form.registration_date).format('DD/MM/YYYY')}}
                              </div>
                            </td>
                          </tr>                             
                        </tbody>
                      </table>                  
                    </b-col>  

                    <b-col md="3" sm="6">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Acta Número</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-right">                                
                              {{this.crud.form.registration_number}}                           
                            </td>
                          </tr>                             
                        </tbody>
                      </table>                   
                    </b-col> 
                    <b-col md="3" sm="6">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Libro</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-right">                                
                              {{this.crud.form.registration_book}}                                
                            </td>
                          </tr>                             
                        </tbody>
                      </table>                   
                    </b-col>  
                    <b-col md="3" sm="6">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Tomo</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-right">                                
                              {{this.crud.form.registration_volume}}                              
                            </td>
                          </tr>                             
                        </tbody>
                      </table>                  
                    </b-col>   
                    <b-col md="3" sm="6">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Follo</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-right">                                
                              {{this.crud.form.registration_sheet}}                           
                            </td>
                          </tr>                             
                        </tbody>
                      </table>                    
                    </b-col>                  

                    <b-col md="12" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>                            
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Dirección Correspondencia</td>
                            <td class="sales-flow-leads-value-table-custom align-middle">                                
                              {{this.crud.form.address_correspondence}}                              
                            </td>
                          </tr>                             
                        </tbody>
                      </table> 
                    </b-col>                                                                        
                  </b-row>
                </b-card-text>
              </b-tab>  

              <b-tab title="Ubicación">               
                <b-card-text>
                  <b-row>
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle text-left">Dirección</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-left">
                              {{this.crud.form.address}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                    
                    </b-col>          
                    <b-col md="4" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle text-left">Ciudad</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-left">
                              {{this.crud.form.city}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                   
                    </b-col>      
                    <b-col md="2" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle text-left">Código Postal</td>
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-left">
                              {{this.crud.form.postal_code}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                   
                    </b-col>  
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Provincia</td>                            
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.state}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                    
                    </b-col>  
                    <b-col md="6" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">País</td>                            
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.country}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                     
                    </b-col>                    
                  </b-row>             
                </b-card-text>
              </b-tab>
              
              <b-tab title="Contacto">   
                <b-card-text>
                  <b-row>     
                    <b-col md="12" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle">Email</td>                            
                            <td class="sales-flow-leads-value-table-custom align-middle">
                              {{this.crud.form.email}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                      
                    </b-col> 

                    <b-col md="4" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle text-center">Teléfono Particular</td>                            
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-center">
                              {{this.crud.form.phone}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                      
                    </b-col> 
                    <b-col md="4" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle text-center">Teléfno Laboral/Comercial</td>                            
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-center">
                              {{this.crud.form.work_phone}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                    
                    </b-col>       
                    <b-col md="4" sm="12">
                      <table class="table table-hover table-sm">
                        <tbody>
                          <tr>
                            <td class="sales-flow-leads-title-table-custom align-middle text-center">Teléfono Celular</td>                            
                          </tr>
                          <tr>
                            <td class="sales-flow-leads-value-table-custom align-middle text-center">
                              {{this.crud.form.mobile}}
                            </td>
                          </tr> 
                        </tbody>
                      </table>                           
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>                                           
            </b-tabs>
          </b-card> 

          <b-row>
            <b-col md="7" sm="12">
              <b-card header="Documentos"
                      header-bg-variant="dark"
                      header-text-variant="withe"
                      no-body
                      class="mb-1">
                
                <div slot="header">
                  <strong>Documentos</strong>                    
                </div>
              
                <b-row>
                  <b-col> 
                    <b-list-group v-if="crud.form.attachments">                          
                      <b-list-group-item :href="value.file" 
                                         target="_blank" 
                                         v-for="value in crud.form.attachments" 
                                         v-bind:key="value.id"
                                         class="sales-flow-leads-list-attach">
                        {{value.name}}
                        <b-icon icon="chevron-double-right"></b-icon>
                      </b-list-group-item>                          
                    </b-list-group>  
                    <div class="p-2" v-else>
                      <b-alert show variant="warning">
                        No se encontraron documentos cargados
                      </b-alert>                    
                    </div>
                  </b-col>
                </b-row>                
              </b-card>
            </b-col>
            <b-col md="5" sm=12>
              <b-card no-body
                      class="mb-1">
                <b-card-header class="sales-flow-leads-success-card-header">
                  <div slot="header">
                    <strong> Aprobación Alta de Cuenta </strong>                    
                  </div>
                </b-card-header>   
                <b-card-body>
                  <b-row>
                    <b-col> 
                      <b-form-group label="Firmar" 
                                    description="Firme en una hoja en blanco y luego suba una foto de la misma (100px alto / 150px ancho)"
                                    class="sales-flow-leads-label-custom">
                        <Imagen :images="crud.formSub.firm" 
                                @load-image="crud.formSub.firm = $event" 
                                typeImage="Firma" 
                                class="sales-flow-leads-image"/>                        
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <b-card header-tag="header" footer-tag="footer" class="mt-1 mb-1">
            <b-row>                
              <b-col>
                <b-button variant="success sales-flow-leads-success-bottom" class="pull-right" @click="approved()">Aprobar</b-button>
                <b-button variant="danger sales-flow-leads-deny-bottom" class="pull-right mr-2" @click="loadLostMotivesForm()">Denegar</b-button>                  
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>  

    <b-container class="mt-2" v-show="crud.form.status=='lost'">
      <b-alert show variant="danger" class="sales-flow-leads-alert">
        <h4 class="alert-heading">Se canceló el alta de cuenta</h4>
        <p>
          El alta de cuenta fue cancelado por el usuario.
        </p>                 
      </b-alert>
    </b-container>

    <b-container class="mt-2" v-show="crud.form.status=='won'">
      <b-alert show variant="success" class="sales-flow-leads-alert">
        <h4 class="alert-heading">Se aprobó el alta de cuenta</h4>
        <p>
          El alta de cuenta fue aprobado por el usuario.
        </p>                 
      </b-alert>
    </b-container>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD GANADO O PERDIDO -->
    <b-modal v-model="modal.stage.active"
            header-bg-variant="danger"              
            header-text-variant="white"
            size="md">
      <div slot="modal-header">
        {{this.modal.stage.title}}
      </div>
              
      <b-row>                   
        <b-col md="12" class="mt-3">
          <p class="text-center">
            Está a punto de cancelar el alta de cuenta.<br> Ayúdenos seleccionando un motivo para poder mejorar en nuestros procesos.
          </p>
        </b-col>
        <b-col md="12" class="mt-3">
          <b-form-group label="Motivo">
            <v-select :options="arr.select.lostMotives" v-model="crud.formSub.lost_motives" placeholder="Motivo" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
          </b-form-group>                 
        </b-col>
      </b-row>                    
      
      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.stage.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="deny()">Continuar</b-button>          
      </div>
    </b-modal>
  </div>
</template>

<script>
import Session from '@/handler/session'
import Profiles from '@/config/profiles'
import Helper from '@/handler/helper'
import serviceAPI from './services'
import Error from '@/handler/error'
import Param from '@/config/parameters'
import Imagen from '@/components/inc/image/image' 

export default {
  components: {    
    Imagen
  },  
  data () {
      return {        
        leadsID: 0,
        parameters: {
          dataComplementary: Helper.hasParametersAccess(Param.P4),       
          valorization: Helper.hasParametersAccess(Param.P5),             
        },        
        crud: {
          form: {
            id: 0,
            date: '',                                   
            name: '',
            last_name: '',
            address: '',
            city: '',
            postal_code: '',
            state: '',
            country: '',
            fiscal_conditions_id: 0,
            fiscal_conditions_name: '',
            documents_types_id: 0,
            documents_types_name: '',
            documents_number: '',
            documents_fiscal_types_id: 0,
            documents_fiscal_types_name: '',
            documents_fiscal_number: '',
            marital_status_id: 0,
            marital_status_name: '',
            nationality: '',
            place_birth: '',
            birthday: null,
            gender: '',
            main_activity: '',
            phone: '',
            work_phone: '',
            mobile: '',
            email: '',
            status: '',
            address_correspondence: '',           
            registration_public_registry: null,
            year_end_date: null,
            registration_district: '',
            registration_number: '',
            registration_date: null,
            registration_book: '',
            registration_volume: '',
            registration_sheet: '',
            observations: '',        
            lost_motives_id: 0,
            lost_motives_name: '',
            categories_id: 0,
            categories_name: '',
            sources_id: 0,
            sources_name: '',
            iscompany: false,
            work_price: 0,
            work_budget: '',
            attachments: [],
            status: ''
          }, 
          formSub: {
            firm: [],
            lost_motives: 0
          }
        },   
        modal: {            
          stage: {
            active: false,
            title: '',
            value: '',
            id: 0
          }
        },        
        arr: {
          select: {
            gender: [],
            lostMotives: [],
          }
        },
        proyectName: '',
        logo: '',
        primaryColor: '',        
      }
  },
  computed: {
    isCompany () {
      if(this.crud.form.iscompany) {
        return true
      } else {
        return false
      }
    }
  },   
  mounted() {        
    this.leadsID = this.$route.params.leadsID.slice(20,-15)

    this.setup()
    this.loadParameters()
    this.selectGender()
    this.selectLostMotives()
    this.showLeads()
  },    
  methods: {
    setup () {
      if(Session.getSession().settings) { 
        this.proyectName = Session.getSession().settings.proyect_name       
        this.logo = Session.getSession().settings.logo        
        this.primaryColor = Session.getSession().settings.color_primary
      }
    },
    loadParameters() {
      let loader = this.$loading.show()
      var result = serviceAPI.obtenerParametros()

      result.then((response) => {
        var data = response.data

        Session.setSessionParameters(data);
        loader.hide()
      })
      .catch(error => {
        this.$awn.alert(Error.showError(error))
        loader.hide()
      });
    },    
    showLeads(){
      let loader = this.$loading.show();
      var result = serviceAPI.mostrarLeads(this.leadsID);

      result.then((response) => {
        loader.hide()      
        var item = response.data
        
        this.crud.form.iscompany = item.iscompany
        this.crud.form.id = item.id      
        this.crud.form.date = item.date
        this.crud.form.name = item.name
        this.crud.form.last_name = item.last_name
        this.crud.form.address = item.address
        this.crud.form.city = item.city
        this.crud.form.postal_code = item.postal_code
        this.crud.form.state = item.state
        this.crud.form.country = item.country
        
        if(item.marital_status) {
          this.crud.form.marital_status_id = item.marital_status.id
          this.crud.form.marital_status_name = item.marital_status.name        
        }
        if(item.fiscal_condition) {
          this.crud.form.fiscal_conditions_id = item.fiscal_condition.id
          this.crud.form.fiscal_conditions_name = item.fiscal_condition.name      
        }
        if(item.document_type) {
          this.crud.form.documents_types_id = item.document_type.id
          this.crud.form.documents_types_name = item.document_type.name        
        }
        if(item.document_fiscal_type) {
          this.crud.form.documents_fiscal_types_id = item.document_fiscal_type.id
          this.crud.form.documents_fiscal_types_name = item.document_fiscal_type.name   
        }

        this.crud.form.documents_number = item.documents_number
        this.crud.form.documents_fiscal_number = item.documents_fiscal_number 
        this.crud.form.nationality = item.nationality
        this.crud.form.place_birth = item.place_birth
        this.crud.form.birthday = item.birthday
        
        if(item.gender) {
          this.crud.form.gender = this.getGenderSelect(item.gender).label
        }
        
        this.crud.form.main_activity = item.main_activity
        this.crud.form.phone = item.phone
        this.crud.form.work_phone = item.work_phone
        this.crud.form.mobile = item.mobile
        this.crud.form.email = item.email        
        this.crud.form.address_correspondence = item.address_correspondence    
        this.crud.form.registration_public_registry = item.registration_public_registry
        this.crud.form.year_end_date = item.year_end_date
        this.crud.form.registration_district = item.registration_district
        this.crud.form.registration_number = item.registration_number
        this.crud.form.registration_date = item.registration_date
        this.crud.form.registration_book = item.registration_book
        this.crud.form.registration_volume = item.registration_volume
        this.crud.form.registration_sheet = item.registration_sheet
        this.crud.form.observations = item.observations 

        if(item.lost_motive) {
          this.crud.form.lost_motives_id = item.lost_motive.id
          this.crud.form.lost_motives_name = item.lost_motive.name
        }
        if(item.categories) {
          this.crud.form.categories_id = item.categories.id
          this.crud.form.categories_name = item.categories.name
        }
        if(item.source) {
          this.crud.form.source_id = item.source.id
          this.crud.form.source_name = item.source.name  
        }
      
        this.crud.form.work_price = item.work_price
        this.crud.form.work_budget = item.work_budget
        this.crud.form.attachments = item.attachments
        this.crud.form.status = item.status
      })
      .catch(error => {            
        loader.hide()
        this.$awn.alert(Error.showError(error));
      })      
    },
    selectGender(){
      this.arr.select.gender = []        
      this.arr.select.gender.push({ code: 'male', label: 'Masculino' })                        
      this.arr.select.gender.push({ code: 'female', label: 'Femenino' })                        
      this.arr.select.gender.push({ code: 'others', label: 'Otros' })      
    },  
    getGenderSelect(value) {
      var result = null
      this.arr.select.gender.forEach(element=>{
        if(element.code==value) {
          result = element
        }
      })
      return result
    }, 
    approved() {      
      if(this.crud.formSub.firm.length==0) {
        this.$awn.alert("Agregue una firma");
        return false
      }

      this.$bvModal.msgBoxConfirm('¿Desea continuar con el alta de cuenta?', {
        title: 'Alta de cuenta',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'dark',
        okTitle: 'Aceptar',
        cancelVariant: 'outline-dark',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        headerBgVariant: 'success',
        headerTextVariant: 'black',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if (value) {      
          let loader = this.$loading.show(); 

          var image = new FormData();
          this.crud.formSub.firm.forEach((value, key) => {
            image.append(key, value.path);
          })

          var result = serviceAPI.guardarApproval(
            JSON.stringify({
              id: this.leadsID, 
              firm: ''               
            }), image
          );

          result.then((response) => {          
            loader.hide()
            this.showLeads()
            this.$awn.success("Datos enviados con éxito");
          })
          .catch(error => {                 
            loader.hide()
            this.$awn.alert(Error.showError(error));
          })
        }
      })
      .catch(error => {       
        this.$awn.alert(Error.showError(error));
      })                                       
    },
    loadLostMotivesForm() {    
      if(this.crud.formSub.firm.length>0) {
        this.$awn.alert("Quite la firma");
        return false
      }

      this.modal.stage.title = 'Seleccionar un Motivo' 
      this.modal.stage.active = true
    },
    deny() {
      this.$bvModal.msgBoxConfirm('¿Desea continuar con la cancelación del alta de cuenta?', {
        title: 'Cancelar alta de cuenta',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'dark',
        okTitle: 'Aceptar',
        cancelVariant: 'outline-dark',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        headerBgVariant: 'danger',
        headerTextVariant: 'black',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if (value) {      
          let loader = this.$loading.show(); 

          var customLostMotives = this.crud.formSub.lost_motives
          this.crud.formSub.lost_motives = this.crud.formSub.lost_motives.code

          var result = serviceAPI.guardarDeny({
            id: this.leadsID, 
            lost_motive: this.crud.formSub.lost_motives                        
          });

          result.then((response) => {          
            loader.hide()
            this.modal.stage.active = false
            this.showLeads()
            this.$awn.success("Datos enviados con éxito");
          })
          .catch(error => {   
            this.crud.formSub.lost_motives = customLostMotives

            loader.hide()
            this.$awn.alert(Error.showError(error));
          })
        }
      })
      .catch(error => {       
        this.$awn.alert(Error.showError(error));
      })                  
    },
    selectLostMotives(){
      var result = serviceAPI.obtenerLeadsLostMotive()        
      result.then((response) => {
        var data = response.data    

        this.arr.select.lostMotives = []
        data.forEach(element => {                                                  
          this.arr.select.lostMotives.push({ code: element.id, label: element.name })                
        });          
      })  
    },      
  }
}
</script>
<style>
  .sales-flow-public-approval-nav {
    background-color: #ffffff !important;
  }
  .sales-flow-leads-title-table-custom {
    color: #000;
    background-color: #e4e5e6;
    border-color: #e4e5e6;
    font-weight: 700;
    padding-right: 10px;
    width: 40%;
    text-align: right
  }
  .sales-flow-leads-value-table-custom {
    padding-left: 5px;
  }
  .sales-flow-leads-success-bottom {
    background-color: darkgreen;
    border-color: darkgreen;
  }
  .sales-flow-leads-deny-bottom {
    background-color: darkred;
    border-color: darkred;
  }
  .sales-flow-leads-success-card-header {
    background-color: darkgreen;
    border-color: darkgreen;
    color: white;    
  }
  .sales-flow-leads-image .image-container{
    width: 100%
  }
  .sales-flow-leads-label-custom legend{
    text-align: left !important;
    font-weight: 600;
    font-size: 16px;
  }
  .sales-flow-leads-label-custom small {
    text-align: left !important;
    font-weight: 600;
    font-size: 12px;
  }
  .sales-flow-leads-alert {
    text-align: center;
  }
  .sales-flow-leads-list-attach {
    color: #000;
  }
</style>